




import { defineComponent, onMounted, useContext } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useMyAccountNavigation } from '@theme/modules/customer/stores/myAccountNavigation';
import T360FormWidget from '@vaimo-t360/components/T360FormWidget.vue';

export default defineComponent({
  name: 'Interests',
  components: {
    T360FormWidget,
  },
  middleware: 'is-authenticated',
  setup() {
    const customerStore = useCustomerStore();
    const { sapUniqueID } = storeToRefs(customerStore);
    const breadcrumbsStore = useMyAccountNavigation();
    const { localePath, i18n } = useContext();

    onMounted(() => {
      breadcrumbsStore.setBreadcrumbs([
        {
          text: i18n.t('Interests') as string,
          link: localePath({ name: 'customer-interests' }),
        }
      ])
    });

    return {
      sapUniqueID,
    }
  }
})
