//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, useContext, useRoute } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'T360FormWidget',
  props: {
    sourceId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const route = useRoute();
    const {
      $config,
      $t360: {
        enabled,
        baseUrl,
        apiKey,
        widgetId,
        encrypt,
      }
    } = useContext();

    const dataSource = computed(() => {
      let source = $config.baseURL;

      if (source.endsWith('/')) {
        source = source.slice(0, -1)
      }

      return `${source}${route.value.path}`;
    });

    const getIframeSrc = () => {
      const url = new URL(`${baseUrl}/widget`);
      const params = new URLSearchParams({
        widgetid: widgetId,
        apiKey: apiKey,
      });

      // eslint-disable-next-line max-len
      const finalUrl = `${url.toString()}?${params.toString()}&source_id=${encrypt(props.sourceId, apiKey)}&dataSource=${dataSource.value}`;

      return finalUrl;
    }


    return {
      dataSource,
      iframeSrc: getIframeSrc(),
      enabled,
    }
  }
});
